import type { ComponentType } from "react"

export const withOperatingSystemVariant = (Component): ComponentType => {
    const getVariant = (): string => {
        if (typeof window !== "undefined") {
            const platform = window.navigator.platform.toLowerCase()
            if (platform.includes("mac")) return "Variant 1"
            if (platform.includes("iphone") || platform.includes("ipad"))
                return "Variant 2"
            if (platform.includes("win")) return "Variant 3"
            if (platform.includes("android")) return "Variant 4"
        }
        return "DefaultVariant" // Fallback variant for server-side rendering or unsupported platforms
    }

    return (props) => {
        const variant = getVariant()
        return <Component {...props} variant={variant} />
    }
}
